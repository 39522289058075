const showLink = (pollingEvent) => {
  const result = pollingEvent?.detail?.result;
  const expenseLink = document.getElementById("expense_link");
  if (result && expenseLink) {
    expenseLink.href += result.expense_report.third_party_id;
    expenseLink.classList.remove("tw-hidden");
  }
};

const init = () => {
  document
    .querySelector('[data-hover="edit-name"]')
    .addEventListener("mouseenter", () => {
      const classes = document.querySelector(
        '[data-content="edit-name"]',
      ).classList;
      classes.remove("tw-hidden");
      classes.add("tw-inline-block");
    });
  document
    .querySelector('[data-hover="edit-name"]')
    .addEventListener("mouseleave", () => {
      const classes = document.querySelector(
        '[data-content="edit-name"]',
      ).classList;
      classes.add("tw-hidden");
      classes.remove("tw-inline-block");
    });
  document
    .querySelector('[data-content="edit-name"]')
    .addEventListener("click", (event) => {
      const button = event.target.classList;
      button.add("tw-hidden");
      button.remove("tw-inline-block");

      document.querySelectorAll('[data-toggle="edit-name"]').forEach((el) => {
        const classes = el.classList;
        classes.toggle("tw-hidden");
        el.tagName === "INPUT" ? el.focus() : null;
        el.tagName === "INPUT"
          ? el.setSelectionRange(el.value.length, el.value.length)
          : null;
      });
    });
};

document.addEventListener("DOMContentLoaded", () => {
  const processingContainer = document.querySelector(
    "#push_expense_processing",
  );
  if (processingContainer) {
    processingContainer.addEventListener("progresspolling:complete", (e) => {
      showLink(e);
    });
  }

  const expenseForm = document.querySelector("#concur_expense_form");
  const editName = document.querySelector('[data-hover="edit-name"]');

  if (expenseForm && editName) {
    init();
  }
});

document.addEventListener("searchdropdown:selected", (event) => {
  if (event?.detail?.select?.id === "third_party_id-container") {
    const reportNameContainer = document.querySelector("#expense_report_name");
    if (reportNameContainer && event?.detail?.target) {
      reportNameContainer.value = event.detail.target.dataset.originalName;
      const nameTitle = document.querySelector("[data-toggle='edit-name']");
      if (nameTitle) {
        nameTitle.innerText = event.detail.target.dataset.originalName;
      }
    }
  }
});
